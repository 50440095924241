



























import format from "date-fns/format";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CosmopolTextArea extends Vue {
  @Prop({ required: false, default: null, type: String })
  private text: string;
  @Prop({ required: false, default: false, type: Boolean })
  private withTime: boolean;
  @Prop({ required: false, default: false, type: Boolean })
  private readonly: boolean;
  @Prop({ required: false, default: 1, type: [Number, String] })
  private rowNumber: number | string;

  private localText: string = null;
  private textTimeout = null;
  private time = null;

  private mounted(): void {
    if (this.text) {
      this.localText = this.text;
    }
    if (this.withTime) {
      this.time = format(new Date(), "HH:mm");
    }
  }

  private onInput(txt: string): void {
    if (this.textTimeout) {
      clearTimeout(this.textTimeout);
    }
    this.textTimeout = setTimeout(() => {
      this.localText = txt;
      this.$emit("on-input", txt);
    }, 200);
  }
}
